import moment from "moment";
import React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink";
import useLocalStorage from "~/hooks/useLocalStorage";
import { Callout, CalloutType } from "~/primitiveComponents/dataDisplay/Callout";
interface KubernetesDeploymentFeedbackProps {
    storageKey: string;
}
function KubernetesDeploymentFeedback(props: KubernetesDeploymentFeedbackProps) {
    const DISMISS_DAYS = 7;
    const [dateDismissed, setDateDismissed] = useLocalStorage<string | undefined>(props.storageKey, undefined);
    const onCloseCallout = () => {
        setDateDismissed(moment().toDate().toString());
    };
    // We dismiss this callout for 7 days
    if (dateDismissed && moment(dateDismissed).add(DISMISS_DAYS, "days").isAfter(moment())) {
        return null;
    }
    return (<Callout title="Introducing the new Kubernetes Object Status feature" type={CalloutType.NewFeature} canClose={true} onClose={onCloseCallout}>
            <ExternalLink href="KubernetesObjectStatus">Learn more</ExternalLink> about how it can help you get better visibility into the status of your Kubernetes objects. Give it a try and{" "}
            <ExternalLink href="ObjectStatusFeedback">share your feedback</ExternalLink> to help us make this feature even better.
        </Callout>);
}
export default KubernetesDeploymentFeedback;
