import * as React from "react";
import { withTheme } from "~/components/Theme";
import styles from "./style.module.less";
const avatarPlaceHolder = require("./img/avatar-placeholder.svg");
const octopusServiceImage = require("./img/octopus-service-440.svg");
const userDefaultImage = require("./img/user-placeholder.svg");
interface AvatarProps {
    size?: number;
    isService: boolean;
    avatarLink: string;
}
export const Avatar: React.StatelessComponent<AvatarProps> = (props) => {
    const size = props.size || 25;
    let imageSource = avatarPlaceHolder;
    if (props.isService) {
        imageSource = octopusServiceImage;
    }
    else if (props.avatarLink) {
        imageSource = `${props.avatarLink}&s=${size}`;
    }
    return withTheme((theme) => (
    // Note: Do NOT use Material-UI's Avatar component to render avatars, as we need a fallback background image and
    // if you try to do this with the <img> tag, it'll then show broken external images on closed networks (I.e. networks
    // that can't access gravatar).
    <div className={styles.avatarContainer} style={{
            backgroundImage: `url("${userDefaultImage}")`,
            width: size + "px",
            height: size + "px",
            backgroundColor: theme.whiteConstant,
        }}>
            <div className={styles.avatarContainer} style={{
            backgroundImage: `url("${imageSource}")`,
            width: size + "px",
            height: size + "px",
        }}/>
        </div>));
};
Avatar.displayName = "Avatar"
export default Avatar;
