import * as React from "react";
const keycode = require("keycode");
export enum Key {
    Esc = "esc",
    Enter = "enter",
    CtrlEnter = "ctrlenter"
}
interface KeyboardHandlerProps {
    className?: string;
    // the return boolean is to indicate if KeyboardHandler should preventDefault()
    registrations: Array<{
        key: Key;
        onKeyPressed(e: KeyboardEvent): boolean;
    }>;
}
const KeyboardHandler: React.FC<KeyboardHandlerProps> = (props) => {
    React.useEffect(() => {
        const hasEscRegistration = (): boolean => {
            const escBinding = props.registrations.find((r) => r.key === Key.Esc);
            return !!escBinding;
        };
        const handleEsc = (event: KeyboardEvent): void => {
            if (!event.defaultPrevented) {
                for (const registration of props.registrations) {
                    if (keycode.codes.esc === keycode(registration.key) && keycode.codes.esc === keycode(keycode(event))) {
                        if (registration.onKeyPressed(event)) {
                            event.preventDefault();
                        }
                    }
                }
            }
        };
        if (hasEscRegistration()) {
            window.document.addEventListener("keydown", handleEsc);
        }
        return () => window.document.removeEventListener("keydown", handleEsc);
    }, [props.registrations]);
    const handleKey = (e: React.KeyboardEvent<HTMLDivElement>): void => {
        const event = e.nativeEvent;
        if (!event.defaultPrevented) {
            const keyName = keycode(event);
            for (const registration of props.registrations) {
                if (keyName === keycode(keycode.codes.enter)) {
                    if (event.ctrlKey) {
                        // Ctrl + Enter
                        if (registration.key === Key.CtrlEnter) {
                            if (registration.onKeyPressed(event)) {
                                event.preventDefault();
                            }
                        }
                    }
                    else {
                        // Enter, mac command+enter will fall into here
                        if (registration.key === Key.Enter) {
                            if (registration.onKeyPressed(event)) {
                                event.preventDefault();
                            }
                        }
                    }
                }
                else {
                    // Any other keys
                    const code = keycode(keyName);
                    if (code !== undefined && // don't want to fall through when undefined === undefined
                        code === keycode(registration.key)) {
                        if (registration.onKeyPressed(event)) {
                            event.preventDefault();
                        }
                    }
                }
            }
        }
    };
    return (<div onKeyDown={handleKey} style={{ width: "100%", height: "100%" }} className={props.className}>
            {props.children}
        </div>);
};
KeyboardHandler.displayName = "KeyboardHandler"
export default KeyboardHandler;
