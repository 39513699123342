/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-eq-null */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import moment = require("moment");
import { repository, session } from "~/clientInstance";
import type { IRecentProjects, IRecentSpaceProjects, IRecentSpaceProjectsProject, IRecentProjectsUserSpaces } from "./IRecentProjects";
const RecentProjectsLocalStorageKey: string = "octoRecentProjects";
const MaxNumberOfTimeStampsToKeep: number = 10;
const MaxNumberOfRecentProjectsToKeep: number = 4;
const DayHours: number = 24;
const ThreeDayHours: number = 24 * 3;
const WeekHours: number = 24 * 7;
const MonthHours: number = 24 * 30;
const ThreeMonthHours: number = 24 * 90;
type RecentSpaceProjectsSubscription = (recentSpaceProjects: IRecentSpaceProjects) => void;
type RecentSpaceProjectsSubscriptionCleanup = () => void;
export class RecentProjects {
    static getInstance() {
        if (RecentProjects.instance == null) {
            RecentProjects.instance = new RecentProjects();
        }
        return RecentProjects.instance;
    }
    private recentSpaceProjectsSubscriptions: RecentSpaceProjectsSubscription[] = [];
    public static SortByScoreThenTime(scoreA: number, scoreB: number, timestampsA: moment.Moment[], timestampsB: moment.Moment[]) {
        return scoreA - scoreB || moment(timestampsA[timestampsA.length - 1]).diff(moment(timestampsB[timestampsB.length - 1]));
    }
    private static instance: RecentProjects;
    public GetRecentProjectListInSpace(): IRecentSpaceProjects {
        const recentProjects: IRecentProjects = this.GetRecentProjects();
        const defaultRecentSpaceProject: IRecentSpaceProjects = {
            SpaceId: repository.spaceId!,
            Projects: [],
            LastVisitedProjectId: "",
        };
        try {
            const recentSpaceProjects = recentProjects.Users.find((x) => x.UserId === session.currentUser!.Id)?.Spaces.find((x) => x.SpaceId === repository.spaceId);
            return recentSpaceProjects ? recentSpaceProjects : defaultRecentSpaceProject;
        }
        catch (e) {
            return defaultRecentSpaceProject;
        }
    }
    public async UpdateAccessedProjectIntoLocalStorage(projectId: string): Promise<void> {
        if (!session.currentUser) {
            // Early exit if we don't know our currentUser (as a lot of this code assumes it exists: session.currentUser!.Id).
            return;
        }
        const recentProjects: IRecentProjects = this.GetRecentProjects();
        const userSpaces = this.GetSpacesForUser(recentProjects);
        const space = this.GetCurrentSpaceInUserSpaces(userSpaces);
        if (space.LastVisitedProjectId === projectId) {
            return;
        }
        space.LastVisitedProjectId = projectId;
        const project = this.GetCurrentProjectInSpace(space, projectId);
        project.AccessCount++;
        this.AddTimestampToProject(project);
        this.CalculateScoreForProject(project);
        this.SortProjectsForSpaceAndLimitToMax(space);
        this.SaveRecentProjectsToLocalStorage(recentProjects);
        this.recentSpaceProjectsSubscriptions.forEach((subscription) => {
            subscription(space);
        });
    }
    public SubscribeToRecentSpaceProjects(subscription: RecentSpaceProjectsSubscription): RecentSpaceProjectsSubscriptionCleanup {
        this.recentSpaceProjectsSubscriptions.push(subscription);
        return () => {
            this.recentSpaceProjectsSubscriptions = this.recentSpaceProjectsSubscriptions.filter((s) => s != subscription);
        };
    }
    private GetSpacesForUser(recentProjects: IRecentProjects): IRecentProjectsUserSpaces {
        let userSpaces = recentProjects.Users.find((x) => x.UserId === session.currentUser!.Id);
        if (userSpaces === undefined) {
            userSpaces = {
                UserId: session.currentUser!.Id,
                Spaces: [],
            };
            recentProjects.Users.push(userSpaces);
        }
        return userSpaces;
    }
    private GetCurrentSpaceInUserSpaces(userSpaces: IRecentProjectsUserSpaces): IRecentSpaceProjects {
        let space = userSpaces.Spaces.find((x) => x.SpaceId === repository.spaceId);
        if (space === undefined) {
            space = {
                SpaceId: repository.spaceId!,
                Projects: [],
                LastVisitedProjectId: "",
            };
            userSpaces.Spaces.push(space);
        }
        return space;
    }
    private GetCurrentProjectInSpace(space: IRecentSpaceProjects, projectId: string): IRecentSpaceProjectsProject {
        let project = space.Projects.find((x) => x.ProjectId === projectId);
        if (project === undefined) {
            project = {
                ProjectId: projectId,
                Timestamps: [],
                AccessCount: 0,
                Score: 0,
            };
            space.Projects.push(project);
        }
        return project;
    }
    private AddTimestampToProject(project: IRecentSpaceProjectsProject, maxTimestamps = MaxNumberOfTimeStampsToKeep) {
        project.Timestamps.push(moment.utc());
        if (project.Timestamps.length > maxTimestamps) {
            project.Timestamps.splice(0, project.Timestamps.length - maxTimestamps);
        }
    }
    private SortProjectsForSpaceAndLimitToMax(space: IRecentSpaceProjects, maxProjects = MaxNumberOfRecentProjectsToKeep) {
        space.Projects.sort((a, b) => RecentProjects.SortByScoreThenTime(a.Score, b.Score, a.Timestamps, b.Timestamps)).reverse();
        while (space.Projects.length > maxProjects) {
            space.Projects.pop();
        }
    }
    private CalculateScoreForProject(project: IRecentSpaceProjectsProject) {
        /*
            https://slack.engineering/a-faster-smarter-quick-switcher-77cbc193cb60
            Within the past 4 hours: 100 points
            Within the past day: 80 points
            Within the past 3 days: 60 points
            Within the past week: 40 points
            Within the past month: 20 points
            Within the past 90 days: 10 points
            Beyond 90 days: 0 points
        */
        let recentAccessScore: number = 0;
        const hoursSinceLastAccess = moment.utc().diff(project.Timestamps[project.Timestamps.length - 1], "hours");
        if (hoursSinceLastAccess <= 4) {
            recentAccessScore = 100;
        }
        else if (hoursSinceLastAccess <= DayHours) {
            recentAccessScore = 80;
        }
        else if (hoursSinceLastAccess <= ThreeDayHours) {
            recentAccessScore = 60;
        }
        else if (hoursSinceLastAccess <= WeekHours) {
            recentAccessScore = 40;
        }
        else if (hoursSinceLastAccess <= MonthHours) {
            recentAccessScore = 20;
        }
        else if (hoursSinceLastAccess <= ThreeMonthHours) {
            recentAccessScore = 10;
        }
        project.Score = (project.AccessCount * recentAccessScore) / project.Timestamps.length;
    }
    private GenerateEmptyRecentProjectsJsonString(): string {
        const recentProjects: IRecentProjects = {
            Users: [],
        };
        return JSON.stringify(recentProjects);
    }
    private SaveRecentProjectsToLocalStorage(recentProjects: IRecentProjects) {
        localStorage.setItem(RecentProjectsLocalStorageKey, JSON.stringify(recentProjects));
    }
    private GetRecentProjects(): IRecentProjects {
        let recentProjectsJsonString = localStorage.getItem(RecentProjectsLocalStorageKey);
        if (!recentProjectsJsonString || recentProjectsJsonString.length <= 0) {
            recentProjectsJsonString = this.GenerateEmptyRecentProjectsJsonString();
            localStorage.setItem(RecentProjectsLocalStorageKey, recentProjectsJsonString);
        }
        return JSON.parse(recentProjectsJsonString) as IRecentProjects;
    }
}
