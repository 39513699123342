import { OctoLink } from "@octopusdeploy/utilities";
const outdatedBrowserRework = require("outdated-browser-rework");
outdatedBrowserRework({
    browserSupport: {
        Edge: 80,
        IE: false,
    },
    requireChromeOnAndroid: false,
    isUnknownBrowserOK: true,
    messages: {
        en: {
            outOfDate: "Your browser is out of date!",
            unsupported: "Your browser is not supported!",
            update: {
                web: "Update your browser to view this website correctly. ",
                googlePlay: "Please install Chrome from Google Play",
                appStore: "Please update iOS from the Settings App",
            },
            url: OctoLink.Create("SupportedBrowsers"),
            callToAction: "See supported browsers",
            close: "Close",
        },
    },
});
import "./outdated-browser.css";
