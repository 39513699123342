import releaseRouteLinks from "../Releases/ReleasesRoutes/releaseRouteLinks";
import { channel, trigger, scheduledTrigger, process } from "../routeLinkHelpers";
export const deploymentLinks = (root: string) => {
    return {
        root,
        channels: `${root}/channels`,
        channel: channel(`${root}/channels`),
        triggers: `${root}/triggers`,
        trigger: trigger(`${root}/triggers`),
        scheduledTrigger: scheduledTrigger(`${root}/triggers`),
        process: process(`${root}/process`),
        settings: `${root}/settings`,
        ...releaseRouteLinks(`${root}/releases`),
    };
};
export default deploymentLinks;
