import type { DeploymentResource, ReleaseResource } from "@octopusdeploy/octopus-server-client";
export enum DeploymentCreateGoal {
    To = "to",
    TryAgain = "try-again"
}
function createLink(root: string, releaseVersion: string | ReleaseResource, goal?: DeploymentCreateGoal | string, previousId?: string, tenantIds?: string | string[]) {
    const previousSegment = previousId ? `/${previousId}` : "";
    const uri = `${root}/${releaseVersion}/deployments/create/${goal}${previousSegment}`;
    if (!tenantIds) {
        return uri;
    }
    const tenants = Array.isArray(tenantIds) ? tenantIds : [tenantIds];
    return `${uri}/${tenants.join(",")}`;
}
export default (root: string) => ({
    releases: root,
    releaseCreate: `${root}/create`,
    release: (releaseVersion: string | ReleaseResource) => {
        if (!!releaseVersion && typeof releaseVersion !== "string") {
            releaseVersion = releaseVersion.Version;
        }
        return {
            root: `${root}/${releaseVersion}`,
            edit: `${root}/${releaseVersion}/edit`,
            deployments: {
                specific: (deploymentId: string | DeploymentResource) => {
                    if (typeof deploymentId !== "string") {
                        deploymentId = deploymentId.Id;
                    }
                    return `${root}/${releaseVersion}/deployments/${deploymentId}`;
                },
                retry: (previousDeploymentId: string) => {
                    return createLink(root, releaseVersion, DeploymentCreateGoal.TryAgain, previousDeploymentId);
                },
                create: (goal?: DeploymentCreateGoal | string, previousId?: string, tenantIds?: string | string[]) => {
                    return createLink(root, releaseVersion, goal, previousId, tenantIds);
                },
            },
        };
    },
});
