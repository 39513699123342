import { createStyles, makeStyles } from "@material-ui/core/styles";
import { DropzoneArea } from "material-ui-dropzone";
import * as React from "react";
import { useErrorActions } from "~/components/ErrorContext/ErrorContext";
import type { OctopusTheme } from "~/components/Theme";
import { useOctopusTheme } from "~/components/Theme";
import styles from "./styles.module.less";
interface FileUploadDropzoneProps {
    onFilesChanged: (newValue: File[]) => void;
    label: string;
    initialFiles?: File[];
    filesLimit?: number;
    showAlerts?: boolean;
    showFileChips?: boolean;
}
const FileUploadDropzone: React.FC<FileUploadDropzoneProps> = (props) => {
    const MAX_SUPPORTED_FILE_SIZE = 1000000000000; // 1TB in bytes
    const { clearErrors } = useErrorActions();
    const handleChange = (files: File[]) => {
        clearErrors();
        props.onFilesChanged(files);
    };
    const handleDelete = (deletedFile: File) => {
        clearErrors();
        props.onFilesChanged([deletedFile]);
    };
    const useStyles = makeStyles(() => createStyles({
        previewChip: (props: {
            octopusTheme: OctopusTheme;
        }) => ({
            minWidth: 160,
            maxWidth: 500,
            width: "auto",
            backgroundColor: props.octopusTheme.chipBackground,
            color: props.octopusTheme.chipText,
            border: "none",
            overflow: "visible",
            textAlign: "left",
            display: "flex",
            justifyContent: "flex-start",
        }),
        deleteIcon: (props: {
            octopusTheme: OctopusTheme;
        }) => ({ color: props.octopusTheme.chipDeleteButton }),
        label: (props: {
            octopusTheme: OctopusTheme;
        }) => ({ color: props.octopusTheme.chipText, overflow: "visible", textAlign: "left" }),
    }));
    const useMuiStyles = makeStyles(() => ({
        root: (props: {
            octopusTheme: OctopusTheme;
        }) => ({ backgroundColor: props.octopusTheme.primaryBackground }),
        active: (props: {
            octopusTheme: OctopusTheme;
        }) => ({ backgroundImage: "none", backgroundColor: props.octopusTheme.secondaryBackground, borderColor: props.octopusTheme.infoBorder }),
        invalid: (props: {
            octopusTheme: OctopusTheme;
        }) => ({ backgroundImage: "none", backgroundColor: props.octopusTheme.dangerTaskLogBackground, borderColor: props.octopusTheme.dangerBorder }),
    }));
    const octopusTheme = useOctopusTheme();
    const previewChipStyles = useStyles({ octopusTheme });
    const classes = useMuiStyles({ octopusTheme });
    const filesLimit = props.filesLimit ?? 1;
    const previewText = filesLimit > 1 ? "Selected files" : "Selected file";
    const getDropRejectMessage = (rejectedFile: File, acceptedFiles: string[], maxFileSize: number) => {
        let message = `File ${rejectedFile.name} was rejected. `;
        if (!acceptedFiles.includes(rejectedFile.type)) {
            message += "File type not supported. ";
        }
        const maxFileSizeInGb = maxFileSize / 1000000000 + " GB";
        if (rejectedFile.size > maxFileSize) {
            message += "File is too big. Size limit is " + maxFileSizeInGb + ". To upload a larger file we recommend using Octo.exe";
        }
        return message;
    };
    return (<div role="input" className={styles.dragDropFile}>
            <DropzoneArea dropzoneText={props.label} showPreviews={props.showFileChips === undefined ? true : props.showFileChips} showAlerts={!props.showAlerts === undefined ? true : props.showAlerts} showPreviewsInDropzone={false} useChipsForPreview previewGridProps={{ container: { spacing: 1, direction: "row" } }} previewChipProps={{ classes: { root: previewChipStyles.previewChip, deleteIcon: previewChipStyles.deleteIcon, label: previewChipStyles.label } }} classes={classes} previewText={previewText} onChange={handleChange} onDelete={handleDelete} filesLimit={filesLimit} initialFiles={props.initialFiles} getDropRejectMessage={getDropRejectMessage} maxFileSize={MAX_SUPPORTED_FILE_SIZE}/>
        </div>);
};
FileUploadDropzone.displayName = "FileUploadDropzone"
export default FileUploadDropzone;
