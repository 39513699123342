import React from "react";
import CopyToClipboard from "~/components/CopyToClipboardButton/CopyToClipboardButton";
import styles from "./style.module.less";
const CopyValueToClipboard: React.FC<{
    value: string;
}> = ({ value }) => {
    return (<div className={styles.copyValue}>
            <strong>{value}</strong>
            <CopyToClipboard value={value}/>
        </div>);
};
CopyValueToClipboard.displayName = "CopyValueToClipboard"
export default CopyValueToClipboard;
