import * as React from "react";
import { Callout, CalloutType } from "~/primitiveComponents/dataDisplay/Callout";
export default class MissingImportExportPermissionCallout extends React.Component {
    render() {
        return (<Callout title="Permission Required" type={CalloutType.Information}>
                The Space Manager permission is required to import/export projects from this space.
            </Callout>);
    }
    static displayName = "MissingImportExportPermissionCallout";
}
