/* eslint-disable @typescript-eslint/no-non-null-assertion */
import moment from "moment";
import * as React from "react";
import type { SFC } from "react";
import DateFormatter from "~/utils/DateFormatter";
const humanizeDuration = require("humanize-duration");
interface DurationBetweenLabelProps {
    from: string;
    to: string;
    titleMode?: DurationBetweenLabelTitleMode;
}
export enum DurationBetweenLabelTitleMode {
    Both,
    From,
    To
}
const getTitle = (props: DurationBetweenLabelProps) => {
    switch (props.titleMode || DurationBetweenLabelTitleMode.Both) {
        case DurationBetweenLabelTitleMode.From:
            return DateFormatter.dateToLongFormat(props.from);
        case DurationBetweenLabelTitleMode.To:
            return DateFormatter.dateToLongFormat(props.from);
        default:
            return DateFormatter.dateToLongFormat(props.from) + " -> " + DateFormatter.dateToLongFormat(props.to);
    }
};
const DurationBetweenLabel: SFC<DurationBetweenLabelProps> = (props) => {
    const from = moment(props.from);
    const to = moment(props.to);
    return (<span title={getTitle(props)!}>
            {humanizeDuration(to.diff(from), {
            round: true,
            largest: 2,
            conjunction: " and ",
            serialComma: false,
        })}
        </span>);
};
export default DurationBetweenLabel;
