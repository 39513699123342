/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import type { ScopeValues, TagResource } from "@octopusdeploy/octopus-server-client";
import { VariableType } from "@octopusdeploy/octopus-server-client";
import { keyBy } from "lodash";
import React, { useEffect, useState } from "react";
import { useOptionalProjectContext } from "~/areas/projects/context";
import type { ScopeSpecification } from "~/areas/variables/ReadonlyVariableResource";
import { AdvancedTenantTagsSelector } from "~/components/AdvancedTenantSelector/AdvancedTenantSelector";
import type { DoBusyTask } from "~/components/DataBaseComponent/DataBaseComponent";
import { ChannelMultiSelect } from "~/components/MultiSelect/ChannelMultiSelect";
import { EnvironmentMultiSelect } from "~/components/MultiSelect/EnvironmentMultiSelect";
import { MachineMultiSelect } from "~/components/MultiSelect/MachineMultiSelect";
import ProcessMultiSelect from "~/components/MultiSelect/ProcessMultiSelect";
import { RoleMultiSelect } from "~/components/MultiSelect/RoleMultiSelect";
import { StepMultiSelect } from "~/components/MultiSelect/StepMultiSelect";
import type { FocusableComponent } from "~/components/VirtualListWithKeyboard/FocusableComponent";
import { TypeaheadMultiStepSelector } from "../../../components/Typeahead/TypeaheadStepSelector";
import * as tenantTagsets from "../../../components/tenantTagsets";
import { useVariableEditorScoping } from "../VariableEditor/VariableEditorScopingContext";
import { isProjectScoped } from "../VariableEditor/types";
import styles from "./style.module.less";
interface ScopeSelectorProps {
    value: ScopeSpecification;
    availableScopes: ScopeValues;
    allowTenantTagSelection: boolean;
    useCompactControls: boolean;
    variableType: VariableType;
    firstComponent?: FocusableComponent;
    firstInputRef?(component: FocusableComponent | null): void;
    doBusyTask: DoBusyTask;
    onScopeSelected: (scope: ScopeSpecification) => void;
}
interface TagSet {
    Tags: TagResource[];
    Description: string;
    SortOrder: number;
    Name: string;
    Id: string;
    Links: {
        [name: string]: string;
    };
}
const ScopeSelector: React.FC<ScopeSelectorProps> = (props) => {
    const handleEnvironmentsChanged = (environments: string[]) => {
        changeScope((s) => ({ ...s, Environment: environments }));
    };
    const handleRolesChanged = (roles: string[]) => {
        changeScope((s) => ({ ...s, Role: roles }));
    };
    const handleMachinesChanged = (machines: string[]) => {
        changeScope((s) => ({ ...s, Machine: machines }));
    };
    const handleStepsChanged = (steps: string[]) => {
        changeScope((s) => ({ ...s, Action: steps }));
    };
    const handleChannelsChanged = (channels: string[]) => {
        changeScope((s) => ({ ...s, Channel: channels }));
    };
    const handleTenantTagsChanged = (tenantTags: string[]) => {
        changeScope((s) => ({ ...s, TenantTag: tenantTags }));
    };
    const handleProcessChanged = (processes: string[]) => {
        changeScope((s) => ({ ...s, ProcessOwner: processes }));
    };
    const changeScope = (updateScope: (scope: ScopeSpecification) => ScopeSpecification) => {
        props.onScopeSelected(updateScope(props.value));
    };
    const projectContext = useOptionalProjectContext();
    const isInVersionControlledScope = projectContext && projectContext.state.gitRef;
    const [tagSets, setTagSets] = useState<TagSet[] | null>(null);
    useEffect(() => {
        async function allowTenantTagSelection() {
            if (props.allowTenantTagSelection) {
                await props.doBusyTask(async () => {
                    const tenantTagMap = keyBy(props.availableScopes.TenantTags, (tag) => tag.Id);
                    const tagSets = await tenantTagsets.getAll();
                    setTagSets(tagSets.map((ts) => ({ ...ts, Tags: ts.Tags.filter((tag) => !!tenantTagMap[tag.CanonicalTagName]) })));
                });
            }
        }
        allowTenantTagSelection();
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
    const scoping = useVariableEditorScoping();
    const showRoleSelect = props.variableType !== VariableType.WorkerPool || !!props.value.Role;
    const showMachineSelect = props.variableType !== VariableType.WorkerPool || !!props.value.Machine;
    return (<div className={styles.scopeContainer}>
            <div className={props.allowTenantTagSelection ? styles.firstColumn : styles.firstColumnFullWidth}>
                <EnvironmentMultiSelect multiSelectRef={props.firstInputRef} value={props.value.Environment ? [...(props.value.Environment as string[])] : []} items={props.availableScopes.Environments} onChange={(e) => handleEnvironmentsChanged(e)} openOnFocus={false} hideFloatingLabel={props.useCompactControls}/>
                {showRoleSelect && (<RoleMultiSelect value={props.value.Role ? [...(props.value.Role as string[])] : []} items={props.availableScopes.Roles.map((r) => r.Id)} onChange={(e) => handleRolesChanged(e)} openOnFocus={false} canAdd={true} hideFloatingLabel={props.useCompactControls}/>)}
                {showMachineSelect && (<MachineMultiSelect value={props.value.Machine ? [...(props.value.Machine as string[])] : []} items={props.availableScopes.Machines} onChange={(m: any) => handleMachinesChanged(m)} openOnFocus={false} hideFloatingLabel={props.useCompactControls}/>)}
                {isProjectScoped(scoping) && (<ProcessMultiSelect value={props.value.ProcessOwner ? [...(props.value.ProcessOwner as string[])] : []} items={props.availableScopes.Processes} openOnFocus={false} hideFloatingLabel={props.useCompactControls} onChange={handleProcessChanged}/>)}
                {isProjectScoped(scoping) && !isInVersionControlledScope && (<StepMultiSelect value={props.value.Action ? [...(props.value.Action as string[])] : []} items={props.availableScopes.Actions} onChange={(s: any) => handleStepsChanged(s)} openOnFocus={false} hideFloatingLabel={props.useCompactControls}/>)}
                {isProjectScoped(scoping) && isInVersionControlledScope && (<TypeaheadMultiStepSelector stepReferences={props.availableScopes.Actions} selectedStepIds={props.value.Action ? [...(props.value.Action as string[])] : []} onStepChange={(s: string[]) => handleStepsChanged(s)} onNewStep={(id: string) => {
                props.availableScopes.Actions.push({ Id: id, Name: id });
                const actions = [...(props.value.Action ? [...(props.value.Action as string[])] : []), id];
                handleStepsChanged(actions);
            }}/>)}
                {isProjectScoped(scoping) && (<ChannelMultiSelect value={props.value.Channel ? [...(props.value.Channel as string[])] : []} items={props.availableScopes.Channels} onChange={(s: any) => handleChannelsChanged(s)} openOnFocus={false} hideFloatingLabel={props.useCompactControls}/>)}
            </div>
            {props.allowTenantTagSelection && tagSets && (<div className={styles.secondColumn}>
                    <AdvancedTenantTagsSelector tagSets={tagSets} selectedTenantTags={props.value.TenantTag ? [...(props.value.TenantTag as string[])] : []} doBusyTask={props.doBusyTask} onChange={(tenantTags) => handleTenantTagsChanged(tenantTags)} showPreviewButton={true} emptyFilterMeansAllTenants={true}/>
                </div>)}
        </div>);
};
ScopeSelector.displayName = "ScopeSelector"
export default ScopeSelector;
