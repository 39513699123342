import type { ActivityLogElement } from "@octopusdeploy/octopus-server-client";
import { chunk } from "lodash";
import * as React from "react";
import type { SFC } from "react";
import TaskLogLineChunk from "./TaskLogLineChunk";
import styles from "./taskLogBlock.module.less";
interface TaskLogLinesProps {
    lines: ActivityLogElement[];
    showAdditional(): void;
}
const TaskLogLines: SFC<TaskLogLinesProps> = (props) => {
    const lines = props.lines;
    return (<React.Fragment>
            {lines && lines.length > 0 && (<div className={styles.lines}>
                    {chunk(lines, 30).map((l, n) => (<TaskLogLineChunk key={n.toString()} lines={l} showAdditional={props.showAdditional}/>))}
                </div>)}
        </React.Fragment>);
};
TaskLogLines.displayName = "TaskLogLines";
export default TaskLogLines;
