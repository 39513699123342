/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import type { IProcessResource, DeploymentStepResource, DeploymentActionResource, ScriptingLanguage } from "@octopusdeploy/octopus-server-client";
import { PackageRequirement, RunCondition, StartTrigger, RunConditionForAction } from "@octopusdeploy/octopus-server-client";
import { v4 } from "uuid";
type Resolver<T> = () => T | ResolverMap<T>;
type ResolverMap<T> = {
    [P in keyof T]: Resolver<T[P]> | T[P] | ResolverMap<T[P]>;
};
type ResolverOrMap<T> = Resolver<T> | ResolverMap<T>;
export const maybeResolve = <T>(resolver: ResolverOrMap<T>): T | ResolverMap<T> => {
    if (typeof resolver === "function") {
        return resolver();
    }
    return resolver;
};
function isArray(value: unknown): value is ResolverOrMap<unknown>[] {
    return Array.isArray(value);
}
export const resolveMap = <T>(options: ResolverMap<T>): T => {
    const keys = Object.keys(options) as Array<keyof typeof options>;
    return keys.reduce((prev, current) => {
        const member = options[current];
        let resolved = maybeResolve<T[typeof current]>(member);
        if (resolved && isArray(resolved)) {
            resolved = resolved.map(resolveMap) as any;
        }
        else if (resolved && typeof resolved === "object") {
            resolved = resolveMap(resolved) as any;
        }
        const res = { ...prev, [current]: resolved };
        return res;
    }, {}) as any;
};
export const incrementingId = (prefix: string) => {
    let id = 1;
    return () => `${prefix}${id++}`;
};
export const generateGuid = v4;
export const generateNullId = (): string | null => null;
export const generateDeploymentProcessId = (projectId: string) => () => `deployment-process-${projectId}`;
export const createResolver = <T>() => (options: ResolverMap<T>) => resolveMap(options);
export const generateProcess = createResolver<IProcessResource>();
export const generateStep = createResolver<DeploymentStepResource>();
export const generateAction = createResolver<DeploymentActionResource>();
export const withIdGeneration = <T extends {
    Id: string;
}>(generator: () => string) => {
    return (options: ResolverMap<T>) => ({ ...options, Id: generator() });
};
export const getInlineScriptProperties = (type: ScriptingLanguage, body: string) => {
    return {
        "Octopus.Action.Script.ScriptSource": "Inline",
        "Octopus.Action.Script.ScriptBody": body,
        "Octopus.Action.Script.Syntax": type,
    };
};
export const generateBasicProcess = (id: string, generateProjectId: () => string, generateSteps: () => DeploymentStepResource[]) => {
    const projectId = generateProjectId();
    return generateProcess({
        Id: id,
        SpaceId: "",
        Links: {
            Project: "",
            Self: "",
            Template: "",
        },
        ProjectId: projectId,
        Steps: generateSteps(),
        Version: 1,
    });
};
type BasicStepOptions = Pick<DeploymentStepResource, "Condition" | "PackageRequirement" | "StartTrigger" | "Properties">;
const getBasicStepOptions = (): BasicStepOptions => {
    return {
        Condition: RunCondition.Success,
        StartTrigger: StartTrigger.StartAfterPrevious,
        PackageRequirement: PackageRequirement.LetOctopusDecide,
        Properties: {},
    };
};
type BasicActionOptions = Pick<DeploymentActionResource, "CanBeUsedForProjectVersioning" | "IsDisabled" | "Notes" | "IsRequired" | "Properties" | "ActionType" | "ExcludedEnvironments" | "Channels" | "Environments" | "Packages" | "Links" | "TenantTags" | "WorkerPoolId" | "WorkerPoolVariable" | "Condition">;
export const getBasicActionOptions = (): BasicActionOptions => {
    return {
        CanBeUsedForProjectVersioning: true,
        IsDisabled: false,
        Notes: null,
        IsRequired: true,
        Properties: {
            "Octopus.Action.RunOnServer": "true", // Be careful about changing this. Script Step actions run on server by default.
        },
        ActionType: "Octopus.Script",
        ExcludedEnvironments: [],
        Channels: [],
        Environments: [],
        Packages: [],
        Links: {},
        WorkerPoolId: null!,
        WorkerPoolVariable: null!,
        TenantTags: [],
        Condition: RunConditionForAction.Success,
    };
};
type GenerateStepOptions = Parameters<typeof generateStep>[0];
export const generateBasicStep = (name: string, configure: (options: GenerateStepOptions) => GenerateStepOptions = (t) => t) => {
    return generateStep(configure({
        ...getBasicStepOptions(),
        Id: generateGuid(),
        Actions: [],
        Name: name,
        Links: {},
    }));
};
type GenerateActionOptions = Parameters<typeof generateAction>[0];
export const generateBasicAction = (name: string, configure: (options: GenerateActionOptions) => GenerateActionOptions = (t) => t) => {
    return generateAction(configure({
        ...getBasicActionOptions(),
        Id: generateGuid(),
        Name: name,
        Container: {
            FeedId: null!,
            Image: null!,
        },
    }));
};
export const generateBasicParentStep = (id: string, name: string, children: string[], configureChild?: (options: GenerateActionOptions) => GenerateActionOptions) => {
    return generateBasicStep(name, (options) => ({ ...options, Id: id, Actions: children.map((x) => generateBasicAction(x, configureChild)) }));
};
export const generateBasicScriptStep = (name: string, type: ScriptingLanguage, body: string) => {
    return generateBasicStep(name, (options) => ({
        ...options,
        Actions: [generateBasicScriptAction(name, type, body)],
    }));
};
export const generateBasicScriptAction = (name: string, type: ScriptingLanguage, body: string) => {
    return generateBasicAction(name, (action) => ({
        ...action,
        ActionType: "Octopus.Script",
        Properties: { ...action.Properties, ...getInlineScriptProperties(type, body) },
    }));
};
