import * as React from "react";
import ExternalLink from "../../../../../components/Navigation/ExternalLink";
import { Note } from "../../../../../components/form";
import type { CategoryDefinition } from "./endpointRegistry";
export function createStepPackageCategoryDefinition(category: string): CategoryDefinition {
    return {
        category: category,
        title: (<React.Fragment>
                What type of <strong>{category} Target</strong> do you want to connect?
            </React.Fragment>),
        help: (<Note>
                Learn more about <ExternalLink href={`${category.replace(/\s/g, "")}Targets`}>{category} Targets</ExternalLink>
            </Note>),
        displayOrder: 35,
    };
}
