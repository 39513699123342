import * as React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout";
type RestrictedPermissionsCalloutProps = {
    isVisible: boolean;
};
export const RestrictedPermissionsCallout: React.FC<RestrictedPermissionsCalloutProps> = ({ isVisible }) => (<>
        {isVisible && (<Callout title="Permissions are restricted" type={CalloutType.Warning}>
                Your current license has restricted permissions, which means that all users are able to do everything in the system. Any changes you make to teams and roles will be overridden by this restriction. You can read more about
                <ExternalLink href="RestrictedPermissions"> restricted permissions</ExternalLink> on our website.
            </Callout>)}
    </>);
RestrictedPermissionsCallout.displayName = "RestrictedPermissionsCallout"
export default RestrictedPermissionsCallout;
