import type { PopperProps } from "@material-ui/core";
import { Popper } from "@material-ui/core";
import * as React from "react";
import type { PropsWithChildren } from "react";
//eslint-disable-next-line @typescript-eslint/no-empty-interface
interface SimplePopperProps extends PopperProps {
}
const SimplePopper: React.FC<PropsWithChildren<SimplePopperProps>> = ({ children, ...props }) => {
    return (<Popper {...props}>
            <>{children}</>
        </Popper>);
};
SimplePopper.displayName = "SimplePopper"
export default SimplePopper;
