/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { VariableType } from "@octopusdeploy/octopus-server-client";
import type { ScopeValues } from "@octopusdeploy/octopus-server-client";
import cn from "classnames";
import * as React from "react";
import { FocusableCellType } from "~/areas/variables/CellFocus/CellFocus";
import { FocusField } from "~/areas/variables/EditVariableDialog/EditVariableDialog";
import VariableCell from "~/areas/variables/VariableCell/VariableCell";
import VariableCellIcon, { CellIcons } from "~/areas/variables/VariableCellIcon/VariableCellIcon";
import VariableMessageCell from "~/areas/variables/VariableMessageCell/VariableMessageCell";
import type { VariableMessages, ValueMessages } from "~/areas/variables/VariableMessages/VariableMessages";
import VariableNameAndDescriptionCell from "~/areas/variables/VariableNameAndDescriptionCell/VariableNameAndDescriptionCell";
import { VariableStatus } from "~/areas/variables/VariableStatusIcon";
import VariableStatusIcon from "~/areas/variables/VariableStatusIcon/VariableStatusIcon";
import type { VariableModel } from "~/areas/variables/VariablesModel/VariablesModel";
import type { DoBusyTask } from "~/components/DataBaseComponent/DataBaseComponent";
import { MeasureWidthOutOfFlow } from "~/components/Measure/MeasureOutOfFlow";
import { OverflowMenu, OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import ReadonlyText from "~/components/ReadonlyText/ReadonlyText";
import { withTheme } from "~/components/Theme";
import type { CertificateIndex } from "~/components/certificates";
import type { SensitiveState } from "~/components/form/Sensitive/Sensitive";
import type { TagIndex } from "~/components/tenantTagsets";
import type { CellAligner } from "~/primitiveComponents/dataDisplay/ScrollTable/ScrollTable";
import type { BorderCss } from "~/utils/BorderCss/BorderCss";
import type { WorkerPoolIndex } from "../../../components/workerPools";
import VariableNameCell from "../VariableNameCell";
import VariableScopeCell from "../VariableScopeCell";
import VariableValueCell from "../VariableValueCell";
import type { VariableValueModel } from "../VariablesModel";
import styles from "./style.module.less";
const keycode = require("keycode");
export const VariableMultiValueRowHeight = 48;
interface VariableMultiValueRowProps {
    variable: VariableModel;
    value: VariableValueModel;
    status: VariableStatus;
    sensitiveState: SensitiveState | undefined;
    existingValue: VariableValueModel | undefined;
    isVariableDeleted: boolean;
    availableScopes: ScopeValues;
    tagIndex: TagIndex;
    certificateIndex: CertificateIndex;
    poolIndex: WorkerPoolIndex;
    variableMessages: VariableMessages;
    valueMessages: ValueMessages;
    showNameCell: boolean;
    gitVariables: boolean;
    cellAligner: CellAligner;
    doBusyTask: DoBusyTask;
    borderStyle: BorderCss;
    focus?: FocusableCellType;
    scopeCellWidth: number | undefined;
    onNameChanged: (variable: VariableModel, name: string) => void;
    onValueChanged: (updatedValue: VariableValueModel) => void;
    onMergeClicked: (variable: VariableModel, value: VariableValueModel) => void;
    onDontMergeClicked: (variable: VariableModel) => void;
    onResetChanges: (value: VariableValueModel) => void;
    onDuplicateVariable: (variable: VariableModel) => void;
    onDuplicate?: (value: VariableValueModel) => void;
    onAddValue: (value: VariableModel, selectedValue: VariableValueModel) => void;
    onDeleteValue: (value: VariableValueModel) => void;
    undoDelete: (value: VariableValueModel) => void;
    openVariableEditor: (value: VariableValueModel, name: string, focus: FocusField) => void;
    changingToReferenceType: (value: VariableValueModel, name: string, referenceType: VariableType) => void;
    onBlur: (value: VariableValueModel, blurredFrom: FocusableCellType) => void;
    onFocus: (value: VariableValueModel, focus: FocusableCellType) => void;
    onNavigateUp: (value: VariableValueModel) => void;
    onNavigateDown: (value: VariableValueModel) => void;
    onSensitiveStateChanged: (value: VariableValueModel, state: SensitiveState) => void;
}
interface VariableMultiValueRowState {
    measuredControlCellWidth: number | undefined;
}
// TODO: Cleanup this component, becaues it no longer needs to handle the single variable case
export default class VariableMultiValueRow extends React.Component<VariableMultiValueRowProps, VariableMultiValueRowState> {
    constructor(props: VariableMultiValueRowProps) {
        super(props);
        this.state = {
            measuredControlCellWidth: undefined,
        };
    }
    shouldComponentUpdate(nextProps: VariableMultiValueRowProps, nextState: VariableMultiValueRowState) {
        return (nextProps.variable !== this.props.variable ||
            nextProps.value !== this.props.value ||
            nextProps.status !== this.props.status ||
            nextProps.sensitiveState !== this.props.sensitiveState ||
            nextProps.existingValue !== this.props.existingValue ||
            nextProps.isVariableDeleted !== this.props.isVariableDeleted ||
            nextProps.availableScopes !== this.props.availableScopes ||
            nextProps.tagIndex !== this.props.tagIndex ||
            nextProps.certificateIndex !== this.props.certificateIndex ||
            nextProps.poolIndex !== this.props.poolIndex ||
            nextProps.variableMessages !== this.props.variableMessages ||
            nextProps.valueMessages !== this.props.valueMessages ||
            nextProps.showNameCell !== this.props.showNameCell ||
            nextProps.cellAligner !== this.props.cellAligner ||
            nextProps.borderStyle !== this.props.borderStyle ||
            nextProps.focus !== this.props.focus ||
            nextProps.scopeCellWidth !== this.props.scopeCellWidth ||
            nextState.measuredControlCellWidth !== this.state.measuredControlCellWidth);
    }
    render() {
        return withTheme((theme) => {
            const isNameCellFocused = this.props.focus === FocusableCellType.Name;
            const isValueCellFocused = this.props.focus === FocusableCellType.Value;
            const isScopeCellEditing = this.props.focus === FocusableCellType.ScopeEdit;
            const isScopeCellViewingAll = this.props.focus === FocusableCellType.ScopeViewAll;
            const allWarningMessages = [...this.props.valueMessages.valueWarningMessages];
            const belongsToMultiValueVariable = this.props.variable.values.length > 1;
            return (<div onKeyDown={(ev) => {
                    const code = keycode(ev);
                    if (code === "down") {
                        this.props.onNavigateDown(this.props.value);
                        ev.preventDefault();
                    }
                    else if (code === "up") {
                        this.props.onNavigateUp(this.props.value);
                        ev.preventDefault();
                    }
                }}>
                    <div style={{
                    height: VariableMultiValueRowHeight,
                }}>
                        {this.props.cellAligner([
                    <VariableNameAndDescriptionCell name={this.props.showNameCell ? (this.props.value.IsEditable ? (<VariableNameCell id={this.props.value.Id} name={this.props.variable.name} placeholder={"Enter name"} isDuplicate={this.props.variableMessages.hasDuplicateName} warningMessages={allWarningMessages} onNameChanged={(name) => this.props.onNameChanged(this.props.variable, name)} deleted={this.props.isVariableDeleted} onOpenEditorClicked={() => this.props.openVariableEditor(this.props.value, this.props.variable.name, FocusField.Name)} onMergeClicked={() => this.props.onMergeClicked(this.props.variable, this.props.value)} onDontMergeClicked={() => this.props.onDontMergeClicked(this.props.variable)} isFocused={isNameCellFocused} onFocus={() => this.props.onFocus(this.props.value, FocusableCellType.Name)} onBlur={() => this.props.onBlur(this.props.value, FocusableCellType.Name)}/>) : (<VariableCell>
                                                <ReadonlyText text={this.props.variable.name}/>
                                            </VariableCell>)) : (<VariableMessageCell warningMessages={allWarningMessages}/>)} description={this.props.value.Description && (<VariableCellIcon type={CellIcons.description} description={this.props.value.Description} onClick={() => this.props.value.IsEditable && this.props.openVariableEditor(this.props.value, this.props.variable.name, FocusField.Description)}/>)}/>,
                    <div className={styles.fullSizeCell} style={{ borderTop: belongsToMultiValueVariable ? this.props.borderStyle.borderCssString : undefined }}>
                                {this.props.value.IsEditable ? (<VariableValueCell id={this.props.value.Id} value={this.props.value.Value} type={this.props.value.Type} sensitiveState={this.props.sensitiveState} certificateIndex={this.props.certificateIndex} poolIndex={this.props.poolIndex} isPromptedVariable={!!this.props.value.Prompt} gitVariables={this.props.gitVariables} placeholder={this.props.value.Prompt ? "Enter default value" : "Enter value"} onValueChanged={(Value) => this.props.onValueChanged({ ...this.props.value, Value })} onVariableTypeChanged={(Type) => this.props.onValueChanged({
                                ...this.props.value,
                                Type,
                                IsSensitive: Type === VariableType.Sensitive,
                            })} deleted={this.props.status === VariableStatus.Deleted} onOpenEditorClicked={() => this.props.openVariableEditor(this.props.value, this.props.variable.name, FocusField.Value)} onChangeToReferenceType={(type) => this.props.changingToReferenceType(this.props.value, this.props.variable.name, type)} isFocused={isValueCellFocused} onSensitiveStateChanged={(state) => this.props.onSensitiveStateChanged(this.props.value, state)} onFocus={() => this.props.onFocus(this.props.value, FocusableCellType.Value)} onBlur={() => this.props.onBlur(this.props.value, FocusableCellType.Value)} existingSensitiveValue={this.props.existingValue && this.props.existingValue.Type === VariableType.Sensitive ? this.props.existingValue.Value : undefined}/>) : (<VariableCell>
                                        <ReadonlyText text={this.props.value.Value!}/>
                                    </VariableCell>)}
                            </div>,
                    <div className={styles.lastColumn} style={{ borderTop: belongsToMultiValueVariable ? this.props.borderStyle.borderCssString : undefined }}>
                                <div className={styles.scopeCell}>
                                    <VariableScopeCell scope={this.props.value.Scope} onScopeChanged={(Scope) => this.props.value.IsEditable && this.props.onValueChanged({ ...this.props.value, Scope })} availableScopes={this.props.availableScopes} tagIndex={this.props.tagIndex} variableType={this.props.value.Type} deleted={this.props.status === VariableStatus.Deleted} isEditing={isScopeCellEditing} isViewingAll={isScopeCellViewingAll} doBusyTask={this.props.doBusyTask} onFocusEdit={() => this.props.value.IsEditable && this.props.onFocus(this.props.value, FocusableCellType.ScopeEdit)} onBlurEdit={() => this.props.value.IsEditable && this.props.onBlur(this.props.value, FocusableCellType.ScopeEdit)} onFocusViewAll={() => this.props.onFocus(this.props.value, FocusableCellType.ScopeViewAll)} onBlurViewAll={() => this.props.onBlur(this.props.value, FocusableCellType.ScopeViewAll)} cellHeight={VariableMultiValueRowHeight} onOpenEditorClicked={() => this.props.value.IsEditable && this.props.openVariableEditor(this.props.value, this.props.variable.name, FocusField.Scope)} containerWidth={this.props.scopeCellWidth !== undefined && this.state.measuredControlCellWidth !== undefined ? this.props.scopeCellWidth - this.state.measuredControlCellWidth : undefined}/>
                                </div>
                                <MeasureWidthOutOfFlow onMeasured={(width) => this.setState({ measuredControlCellWidth: width })} 
                    // remeasure if the status changes, because this could toggle whether an icon appears or not
                    key={this.props.status}>
                                    <div className={cn(styles.controlCell, belongsToMultiValueVariable ? styles.multiVariableCell : null)}>
                                        <div onClick={() => this.props.value.IsEditable && this.props.openVariableEditor(this.props.value, this.props.variable.name, null!)}>
                                            <VariableStatusIcon status={this.props.status}/>
                                        </div>
                                        {this.props.value.IsEditable && <OverflowMenu colorOverride={belongsToMultiValueVariable ? theme.secondaryText : undefined} menuItems={this.menuItems(belongsToMultiValueVariable)} tabIndex={-1}/>}
                                    </div>
                                </MeasureWidthOutOfFlow>
                            </div>,
                ])}
                    </div>
                </div>);
        });
    }
    private menuItems(belongsToMultiValueVariable: boolean) {
        if (this.props.status !== VariableStatus.Deleted) {
            const options = [];
            if (!belongsToMultiValueVariable) {
                options.push(OverflowMenuItems.item("Add Value", () => this.props.onAddValue(this.props.variable, this.props.value)));
            }
            options.push(...this.duplicateOptions(belongsToMultiValueVariable));
            options.push(...this.modifiedOptions());
            options.push(OverflowMenuItems.item("Delete Value", () => this.props.onDeleteValue(this.props.value)));
            return options;
        }
        else {
            return [OverflowMenuItems.item("Undo Delete Value", () => this.props.undoDelete(this.props.value))];
        }
    }
    private modifiedOptions() {
        return this.props.status === VariableStatus.Modified ? [OverflowMenuItems.item("Reset Changes", () => this.props.onResetChanges(this.props.value))] : [];
    }
    private duplicateOptions(belongsToMultiValue: boolean) {
        if (belongsToMultiValue) {
            return [
                OverflowMenuItems.item("Duplicate Value", () => {
                    if (this.props.onDuplicate) {
                        this.props.onDuplicate(this.props.value);
                    }
                }),
            ];
        }
        return [OverflowMenuItems.item("Duplicate Value", () => this.props.onDuplicateVariable(this.props.variable))];
    }
    static displayName = "VariableMultiValueRow";
}
