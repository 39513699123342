import type { InsightsDataSeriesResource, InsightsDataSeriesResourceInterval, InsightsFailureRateMetric } from "@octopusdeploy/octopus-server-client";
import type { InsightsUnitOfTime, ParsedInsightsDurationMetrics } from "app/areas/insights/dataTransformation/parseInsightsDurationMetrics";
import { getRecommendedUnitOfTimeKey, parseInsightsDurationMetrics } from "app/areas/insights/dataTransformation/parseInsightsDurationMetrics";
import type { MultiSeriesData } from "~/areas/insights/components/Charts/LineChart";
type OmittedMetrics = "LeadTime" | "MeanTimeToRecovery" | "TimeSincePreviousDeploymentAggregate" | "DeploymentQueuedDuration" | "SuccessfulDeploymentDuration";
interface ExtendedDeploymentFailureRate extends InsightsFailureRateMetric {
    ComputedFailureRate: number;
}
export interface ParsedInsightsAllMetricsInterval extends Omit<InsightsDataSeriesResourceInterval, OmittedMetrics> {
    LeadTime: ParsedInsightsDurationMetrics;
    MeanTimeToRecovery: ParsedInsightsDurationMetrics;
    TimeSincePreviousDeploymentAggregate: ParsedInsightsDurationMetrics;
    DeploymentFailureRate: ExtendedDeploymentFailureRate;
    DeploymentQueuedDuration: ParsedInsightsDurationMetrics;
    SuccessfulDeploymentDuration: ParsedInsightsDurationMetrics;
}
interface ComputedUnitOfTimes {
    LeadTimeKey: InsightsUnitOfTime;
    MeanTimeToRecoveryKey: InsightsUnitOfTime;
    TimeSincePreviousDeploymentKey: InsightsUnitOfTime;
    DeploymentQueuedDurationKey: InsightsUnitOfTime;
    SuccessfulDeploymentDurationKey: InsightsUnitOfTime;
}
export function getYAxisUnitOfTimeKeys(data: MultiSeriesData<ParsedInsightsAllMetricsInterval>[]): ComputedUnitOfTimes {
    const leadTime: ParsedInsightsDurationMetrics[] = [];
    const timeToRecovery: ParsedInsightsDurationMetrics[] = [];
    const timeSincePreviousDeployment: ParsedInsightsDurationMetrics[] = [];
    const deploymentQueued: ParsedInsightsDurationMetrics[] = [];
    const deploymentRun: ParsedInsightsDurationMetrics[] = [];
    data.forEach((s) => {
        s.data.forEach((d) => {
            leadTime.push(d.LeadTime);
            timeToRecovery.push(d.MeanTimeToRecovery);
            timeSincePreviousDeployment.push(d.TimeSincePreviousDeploymentAggregate);
            deploymentQueued.push(d.DeploymentQueuedDuration);
            deploymentRun.push(d.SuccessfulDeploymentDuration);
        });
    });
    return {
        LeadTimeKey: getRecommendedUnitOfTimeKey(leadTime),
        MeanTimeToRecoveryKey: getRecommendedUnitOfTimeKey(timeToRecovery),
        TimeSincePreviousDeploymentKey: getRecommendedUnitOfTimeKey(timeSincePreviousDeployment),
        DeploymentQueuedDurationKey: getRecommendedUnitOfTimeKey(deploymentQueued),
        SuccessfulDeploymentDurationKey: getRecommendedUnitOfTimeKey(deploymentRun),
    };
}
function parseAllMetricsInterval(interval: InsightsDataSeriesResourceInterval) {
    return {
        ...interval,
        LeadTime: parseInsightsDurationMetrics(interval.LeadTime),
        MeanTimeToRecovery: parseInsightsDurationMetrics(interval.MeanTimeToRecovery),
        TimeSincePreviousDeploymentAggregate: parseInsightsDurationMetrics(interval.TimeSincePreviousDeployment),
        DeploymentQueuedDuration: parseInsightsDurationMetrics(interval.DeploymentQueuedDuration),
        SuccessfulDeploymentDuration: parseInsightsDurationMetrics(interval.SuccessfulDeploymentDuration),
        DeploymentFailureRate: {
            ...interval.DeploymentFailureRate,
            ComputedFailureRate: interval.DeploymentFailureRate.Total > 0 ? Number(((interval.DeploymentFailureRate.Failed / interval.DeploymentFailureRate.Total) * 100).toFixed(1)) : 0,
        },
    };
}
export function getChartReadyInsightsData(series: InsightsDataSeriesResource[]): MultiSeriesData<ParsedInsightsAllMetricsInterval>[] {
    return series.map((d, idx) => ({
        name: d.Name ?? "[Hidden name]",
        id: d.Name ?? `Series ${idx}`,
        data: d.Intervals.map((i) => parseAllMetricsInterval(i)),
    }));
}
