import cn from "classnames";
import * as React from "react";
import styles from "./style.module.less";
interface TransitionAnimationProps {
    disableAnimation?: boolean;
}
interface TransitionAnimationState {
    isLoaded: boolean;
}
class TransitionAnimation extends React.Component<TransitionAnimationProps, TransitionAnimationState> {
    constructor(props: TransitionAnimationProps) {
        super(props);
        this.state = { isLoaded: false };
    }
    componentDidMount() {
        this.setState({ isLoaded: true });
    }
    render() {
        if (this.props.disableAnimation) {
            return this.props.children;
        }
        return <div className={cn(!this.props.disableAnimation && (this.state.isLoaded ? styles.fadeIn : styles.notVisible), styles.container, null)}>{this.props.children}</div>;
    }
    static displayName = "TransitionAnimation";
}
export default TransitionAnimation;
