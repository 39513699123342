import React from "react";
import type { ActionType } from "typesafe-actions";
import { createAction, getType } from "typesafe-actions";
import { combineConstantReducers } from "~/utils/Reducers/combineConstantReducers";
export interface DevToolsTabState {
    name: string;
    children: React.ReactNode;
}
export const actions = {
    registerTab: createAction("devtools/registertab", (state: DevToolsTabState) => state)(),
    unregisterTab: createAction("devtools/unregistertab", (name: string) => name)(),
    openDrawer: createAction("drawer/open")(),
    toggleDrawer: createAction("drawer/toggle")(),
    closeDrawer: createAction("drawer/close")(),
    toggleFullscreen: createAction("drawer/toggle/fullscreen")(),
};
type DevToolsActions = ActionType<typeof actions>;
interface DevDrawerState {
    open: boolean;
    fullscreen: boolean;
}
const drawerReducer = (state: DevDrawerState, action: DevToolsActions) => {
    switch (action.type) {
        case getType(actions.openDrawer):
            return { ...state, open: true };
        case getType(actions.closeDrawer):
            return { ...state, open: false };
        case getType(actions.toggleDrawer):
            return { ...state, open: !state.open };
        case getType(actions.toggleFullscreen):
            return { ...state, fullscreen: !state.fullscreen };
    }
    return state;
};
interface DevToolsTabsState {
    tabs: Map<string, React.ReactNode>;
}
const devToolsTabsReducer = (state: DevToolsTabsState, action: DevToolsActions): DevToolsTabsState => {
    switch (action.type) {
        case getType(actions.registerTab):
            state.tabs.set(action.payload.name, action.payload.children);
            return {
                ...state,
            };
        case getType(actions.unregisterTab):
            state.tabs.delete(action.payload);
            return {
                ...state,
            };
    }
    return state;
};
export interface DevToolsContextState {
    drawer: DevDrawerState;
    devToolsTabs: DevToolsTabsState;
}
export type DevToolsContextActions = {
    dispatch: React.Dispatch<DevToolsActions>;
};
export const reducer = combineConstantReducers<DevToolsContextState, DevToolsActions>({
    drawer: drawerReducer,
    devToolsTabs: devToolsTabsReducer,
});
const DevToolsContextStateInternal = React.createContext<DevToolsContextState | undefined>(undefined);
const DevToolsContextActionsInternal = React.createContext<DevToolsContextActions | undefined>(undefined);
const INITIAL_DEVTOOLS_STATE: DevToolsContextState = {
    drawer: { open: false, fullscreen: false },
    devToolsTabs: { tabs: new Map<string, React.ReactNode>() },
};
export const DevToolsContextProvider: React.FC = (props) => {
    const [state, dispatch] = React.useReducer(reducer, INITIAL_DEVTOOLS_STATE);
    return (<DevToolsContextStateInternal.Provider value={state}>
            <DevToolsContextActionsInternal.Provider value={{ dispatch }}>{props.children}</DevToolsContextActionsInternal.Provider>
        </DevToolsContextStateInternal.Provider>);
};
DevToolsContextProvider.displayName = "DevToolsContextProvider"
DevToolsContextProvider.displayName = "DevToolsContextProvider";
export const useDevToolsState = () => {
    return React.useContext(DevToolsContextStateInternal);
};
export const useDevToolsDispatch = () => {
    return React.useContext(DevToolsContextActionsInternal);
};
type DevToolsTabProps = DevToolsTabState;
export const DevToolsTab: React.FC<DevToolsTabProps> = ({ name, children }) => {
    useDevToolsTabEffect(name, children);
    return null;
};
DevToolsTab.displayName = "DevToolsTab"
export const useDevToolsTabEffect = (name: string, children: React.ReactNode) => {
    const context = React.useContext(DevToolsContextActionsInternal);
    const dispatch = context?.dispatch;
    React.useEffect(() => {
        dispatch?.(actions.registerTab({ name, children }));
        return () => {
            dispatch?.(actions.unregisterTab(name));
        };
    }, [dispatch, children, name]);
};
