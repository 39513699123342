import * as React from "react";
import { Section } from "~/components/Section/Section";
import { Callout, CalloutType } from "~/primitiveComponents/dataDisplay/Callout/Callout";
const DeprecatedEndpoint: React.SFC = () => {
    return (<Section>
            <Callout type={CalloutType.Danger} title="Deprecated">
                This endpoint has been deprecated and is no longer supported by the Octopus UI.
            </Callout>
        </Section>);
};
DeprecatedEndpoint.displayName = "DeprecatedEndpoint"
export default DeprecatedEndpoint;
