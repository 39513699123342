import React, { useState } from "react";
import { Text } from "~/components/form";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout";
interface DeleteRunbookProps {
    runbookName: string;
    onChange(canDelete: boolean): void;
    onCommitMessageUpdate(message: string): void;
}
const DeleteRunbook: React.FC<DeleteRunbookProps> = ({ runbookName, onChange, onCommitMessageUpdate }) => {
    const [confirmationRunbookName, updateConfirmationRunbookName] = useState<string>("");
    const onRunbookNameConfirmation = (confirmationRunbookName: string) => {
        updateConfirmationRunbookName(confirmationRunbookName);
        onChange(confirmationRunbookName.replace(/\s+/g, " ").toLowerCase() === runbookName.replace(/\s+/g, " ").toLowerCase());
    };
    return (<div>
            <Callout title="This is a destructive action" type={CalloutType.Danger}>
                This action <strong>cannot</strong> be undone. This will permanently delete the <strong>{runbookName}</strong> Runbook and all of its contents, including Runbook Snapshots and Runbook Runs history.
            </Callout>
            <div>
                <p>Please type in the name of the Runbook ({runbookName}) to confirm.</p>
                <Text accessibleName={"Name of runbook to be deleted"} value={confirmationRunbookName} onChange={onRunbookNameConfirmation}/>
            </div>
        </div>);
};
DeleteRunbook.displayName = "DeleteRunbook"
export default DeleteRunbook;
