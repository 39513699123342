import isEqual from "lodash/isEqual";
import React, { useEffect } from "react";
export function useKeyCombo(keyCombo: string[]) {
    const [keys, setKeys] = React.useState<string[]>([]);
    const [hasKeyComboTriggered, setHasKeyComboTriggered] = React.useState(false);
    const keyHandler = React.useCallback((event: KeyboardEvent) => {
        setKeys((prev) => [...prev, event.key]);
    }, []);
    React.useEffect(() => {
        window.document.addEventListener("keydown", keyHandler);
        return () => window.document.removeEventListener("keydown", keyHandler);
    });
    useEffect(() => {
        if (keys.length > 0) {
            const timeout = setTimeout(() => setKeys([]), 5000);
            return () => clearTimeout(timeout);
        }
    }, [keys]);
    React.useEffect(() => {
        if (isEqual(keys, keyCombo)) {
            setHasKeyComboTriggered(true);
        }
    }, [keyCombo, keys]);
    return hasKeyComboTriggered;
}
