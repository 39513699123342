import * as React from "react";
import { Note } from "~/components/form";
import ExternalLink from "../../../../../components/Navigation/ExternalLink/ExternalLink";
import type { CategoryDefinition } from "./endpointRegistry";
const category: CategoryDefinition = {
    category: "Offline Package Drop",
    title: (<>
            Let's setup an <strong>Offline Package Drop</strong>
        </>),
    help: (<Note>
            Learn more about <ExternalLink href="OfflinePackageDrop">Offline Package Drops</ExternalLink>
        </Note>),
    displayOrder: 45,
};
export default category;
