/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-eq-null */
import type { VersionRuleTestResponse } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import styles from "./style.module.less";
const CodeMirror = require("@skidding/react-codemirror");
interface CodeMirrorProps {
    value: string;
    onChange: (text: string) => void;
}
export default class RulesTester extends React.Component<CodeMirrorProps> {
    private codeMirrorInstance: any;
    setValue(text: string) {
        this.codeMirrorInstance.getCodeMirror().setValue(text);
    }
    showResults(getResult: (version: string) => VersionRuleTestResponse) {
        const doc = this.codeMirrorInstance.getCodeMirror();
        doc.getAllMarks().forEach((marker: any) => {
            marker.clear();
        });
        doc.clearGutter(styles.codeGutter);
        doc.eachLine((line: any) => {
            if (line.text.length === 0) {
                return;
            }
            const lineNumber = doc.getLineNumber(line);
            const ruleResult = getResult(line.text);
            if (ruleResult.Errors.length > 0) {
                doc.setGutterMarker(line, styles.codeGutter, this.makeMarker(styles.badRuleIcon, "exclamation-triangle", "Not a valid version"));
                doc.markText({ line: lineNumber, ch: 0 }, { line: lineNumber, ch: line.text.length }, { className: styles.badRule });
                return;
            }
            if (ruleResult.SatisfiesPreReleaseTag && ruleResult.SatisfiesVersionRange) {
                doc.setGutterMarker(line, styles.codeGutter, this.makeMarker(styles.validRuleIcon, "check", "Version will trigger this rule"));
                doc.markText({ line: lineNumber, ch: 0 }, { line: lineNumber, ch: line.text.length }, { className: styles.validRule });
            }
            else if (ruleResult.SatisfiesPreReleaseTag && !ruleResult.SatisfiesVersionRange) {
                const firstDash = this.getQualifierSeparator(line.text);
                if (firstDash === -1) {
                    doc.setGutterMarker(line, styles.codeGutter, this.makeMarker(styles.invalidRuleIcon, "times", "Version not compatible with this rule"));
                    doc.markText({ line: lineNumber, ch: 0 }, { line: lineNumber, ch: line.text.length }, { className: styles.invalidRule });
                    return;
                }
                doc.setGutterMarker(line, styles.codeGutter, this.makeMarker(styles.invalidRuleIcon, "times", "Pre-release tag is compatible but the version is not"));
                doc.markText({ line: lineNumber, ch: 0 }, { line: lineNumber, ch: firstDash }, { className: styles.invalidRule });
                doc.markText({ line: lineNumber, ch: firstDash }, { line: lineNumber, ch: line.text.length }, { className: styles.validRule });
            }
            else if (!ruleResult.SatisfiesPreReleaseTag && ruleResult.SatisfiesVersionRange) {
                const firstDash = this.getQualifierSeparator(line.text);
                if (firstDash === -1) {
                    doc.setGutterMarker(line, styles.codeGutter, this.makeMarker(styles.invalidRuleIcon, "times", "Pre-release tag is not compatible with this rule"));
                    doc.markText({ line: lineNumber, ch: 0 }, { line: lineNumber, ch: line.text.length }, { className: styles.validRule });
                    return;
                }
                doc.setGutterMarker(line, styles.codeGutter, this.makeMarker(styles.invalidRuleIcon, "times", "Pre-release tag is not compatible with this rule"));
                doc.markText({ line: lineNumber, ch: 0 }, { line: lineNumber, ch: firstDash }, { className: styles.validRule });
                doc.markText({ line: lineNumber, ch: firstDash }, { line: lineNumber, ch: line.text.length }, { className: styles.invalidRule });
            }
            else {
                doc.setGutterMarker(line, styles.codeGutter, this.makeMarker(styles.invalidRuleIcon, "times", "Version not compatible with this rule"));
                doc.markText({ line: lineNumber, ch: 0 }, { line: lineNumber, ch: line.text.length }, { className: styles.invalidRule });
            }
        });
    }
    /**
     * This method will find the start of the maven qualifier or nuget tag.
     * @param {string} line The text to inspect
     * @returns {number} The start of the qualifier or tag
     */
    getQualifierSeparator(line: string): number {
        const result = /(?:(?:v|V)?(?:\d+[.-]?)*\d+)(?=[^0-9]|$)/.exec(line);
        if (result === null) {
            /*
             If there was no match, we have an invalid semver, or a maven
             version that is considered to be all qualifier. In this case
             we return 0.
            */
            return 0;
        }
        /*
            If we found no qualifier or tag, return -1. Otherwise return
            the index of the start of the qualifier or tag.
         */
        return result[0].length === line.length ? -1 : result[0].length;
    }
    render() {
        return (<CodeMirror ref={(ref: any) => (this.codeMirrorInstance = ref)} value={this.props.value} onChange={(text: any) => this.props.onChange(text)} options={{
                lineWrapping: true,
                lineNumbers: false,
                mode: null,
                gutters: [styles.codeGutter],
            }}/>);
    }
    private showTooltip(e: any, content: any) {
        const tt = document.createElement("div");
        tt.className = styles.tooltip;
        tt.appendChild(content.cloneNode(true));
        document.body.appendChild(tt);
        const position = (evt: any) => {
            if (!tt.parentNode) {
                return this.codeMirrorInstance.getCodeMirrorInstance().off(document, "mousemove", position);
            }
            tt.style.top = Math.max(0, evt.clientY - tt.offsetHeight - 5) + "px";
            tt.style.left = evt.clientX + 5 + "px";
        };
        this.codeMirrorInstance.getCodeMirrorInstance().on(document, "mousemove", position);
        position(e);
        if (tt.style.opacity != null) {
            tt.style.opacity = "1";
        }
        return tt;
    }
    private rm(elt: any) {
        if (elt.parentNode) {
            elt.parentNode.removeChild(elt);
        }
    }
    private hideTooltip(tt: any) {
        if (!tt.parentNode) {
            return;
        }
        if (tt.style.opacity == null) {
            this.rm(tt);
        }
        tt.style.opacity = 0;
        setTimeout(() => this.rm(tt), 600);
    }
    private showTooltipFor(e: any, content: any, node: any) {
        let tooltip = this.showTooltip(e, content);
        const hide = () => {
            this.codeMirrorInstance.getCodeMirrorInstance().off(node, "mouseout", hide);
            if (tooltip) {
                this.hideTooltip(tooltip);
                tooltip = null!;
            }
        };
        const poll: any = setInterval(() => {
            if (tooltip) {
                for (let n = node;; n = n.parentNode) {
                    if (n && n.nodeType === 11) {
                        n = n.host;
                    }
                    if (n === document.body) {
                        return;
                    }
                    if (!n) {
                        hide();
                        break;
                    }
                }
            }
            if (!tooltip) {
                return clearInterval(poll);
            }
        }, 400);
        this.codeMirrorInstance.getCodeMirrorInstance().on(node, "mouseout", hide);
    }
    private makeMarker(alert: any, icon: any, tooltip: any) {
        const marker = document.createElement("div");
        marker.className = alert;
        const i = marker.appendChild(document.createElement("i"));
        i.className = `fa-solid fa-${icon}`;
        const tipLabel = document.createDocumentFragment();
        tipLabel.appendChild(document.createTextNode(tooltip));
        this.codeMirrorInstance.getCodeMirrorInstance().on(i, "mouseover", (e: any) => {
            this.showTooltipFor(e, tipLabel, i);
        });
        return marker;
    }
    static displayName = "RulesTester";
}
