import { noOp } from "@octopusdeploy/utilities";
import type { Moment } from "moment";
import * as React from "react";
import TimePicker from "~/areas/projects/components/Releases/Deployments/NowOrLater/TimePicker/timePicker";
import { ExpandableFormSection, Summary } from "~/components/form";
import DateFormatter from "~/utils/DateFormatter/DateFormatter";
interface ScheduleRunAfterAndRunUntilProps {
    runAfter?: Moment;
    runUntil?: Moment;
    onRunAfterChanged?(runAfter: Moment): void;
    onRunUntilChanged?(runUntil: Moment): void;
}
export default class ScheduleRunAfterAndRunUntil extends React.Component<ScheduleRunAfterAndRunUntilProps> {
    render() {
        return (<div>
                {this.props.runAfter && (<ExpandableFormSection errorKey="ScheduleRunAfterTime" title="Run After" summary={this.props.runAfter
                    ? Summary.summary(<span>
                                          <strong>{DateFormatter.dateToCustomFormat(this.props.runAfter, "hh:mm A")}</strong>
                                      </span>)
                    : Summary.placeholder("Please select at what time the schedule should begin processing the execution")} help="Select at what time the schedule should begin processing the execution">
                        <TimePicker time={this.props.runAfter} onTimeChanged={this.props.onRunAfterChanged || noOp}/>
                    </ExpandableFormSection>)}
                {this.props.runUntil && (<ExpandableFormSection errorKey="ScheduleRunUntilTime" title="Run Until" summary={this.props.runUntil
                    ? Summary.summary(<span>
                                          <strong>{DateFormatter.dateToCustomFormat(this.props.runUntil, "hh:mm A")}</strong>
                                      </span>)
                    : Summary.placeholder("Please select at what time the schedule should end processing the execution")} help="Select at what time the schedule should end processing the execution">
                        <TimePicker time={this.props.runUntil} onTimeChanged={this.props.onRunUntilChanged || noOp}/>
                    </ExpandableFormSection>)}
            </div>);
    }
    static displayName = "ScheduleRunAfterAndRunUntil";
}
