/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { ReferenceDataItem, MachineModelHealthStatus, DeploymentTemplateStep, DeploymentPreviewBaseResource } from "@octopusdeploy/octopus-server-client";
import * as _ from "lodash";
import { flatten, groupBy } from "lodash";
export class ActionInfo {
    ActionId: string = undefined!;
    ActionName: string = undefined!;
    ActionNumber: string = undefined!;
    CanBeSkipped: boolean = undefined!;
    IsDisabled: boolean = undefined!;
    Machines: MachineDeploymentPreview[] = undefined!;
    UnavailableMachines: ReferenceDataItem[] = undefined!;
    HasNoApplicableMachines: boolean = undefined!;
    HasOldCalamariVersion: boolean = undefined!;
}
export class ActionToggleInfo {
    Id: string = undefined!;
    Name: string = undefined!;
    details: ActionInfo = undefined!;
}
export class MachineDeploymentPreview {
    Id: string = undefined!;
    Name: string = undefined!;
    HasLatestCalamari: boolean = undefined!;
    isUnavailable: boolean = undefined!;
    HealthStatus?: MachineModelHealthStatus;
}
class DeploymentStepsWorker {
    static getDeploymentSteps(previews: DeploymentPreviewBaseResource[]) {
        const stepGroups = groupBy(flatten(previews.map((p) => p.StepsToExecute)), "ActionId");
        const steps = _.chain(stepGroups)
            .map((sts: DeploymentTemplateStep[], actionId: string) => {
            const firstStep = sts[0];
            let applicable = false;
            _.each(sts, (step) => {
                if (!step.HasNoApplicableMachines) {
                    applicable = true;
                }
            });
            const unavailableIds: any = {};
            const unavailableMachines = _.chain(sts)
                .map((s) => {
                return s.UnavailableMachines;
            })
                .flatten<ReferenceDataItem>()
                .uniqBy((m) => {
                return m.Id;
            })
                .each((m) => {
                unavailableIds[m.Id] = m;
            })
                .value();
            const allMachines = _.chain(sts)
                .flatMap((s) => s.Machines)
                .uniqBy((m) => {
                return m.Id;
            })
                .map((m) => {
                return { ...m, isUnavailable: unavailableIds[m.Id] };
            })
                .value();
            const hasOldCalamariVersion = !!_.find(allMachines, (m) => {
                return !m.HasLatestCalamari;
            });
            const result: ActionToggleInfo = {
                Id: firstStep.ActionId,
                Name: firstStep.ActionName,
                details: {
                    ActionId: firstStep.ActionId,
                    ActionName: firstStep.ActionName,
                    ActionNumber: firstStep.ActionNumber,
                    CanBeSkipped: firstStep.CanBeSkipped,
                    IsDisabled: firstStep.IsDisabled,
                    Machines: allMachines,
                    UnavailableMachines: unavailableMachines,
                    HasNoApplicableMachines: !applicable,
                    HasOldCalamariVersion: hasOldCalamariVersion,
                },
            };
            return result;
        })
            .sortBy((s) => {
            return parseInt(s.details.ActionNumber, NaN);
        })
            .value();
        return steps;
    }
}
export default DeploymentStepsWorker;
