import type { CertificateResource, CertificateDataFormat, SensitiveValue } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import OkDialogLayout from "~/components/DialogLayout/OkDialogLayout";
import ExternalLink from "~/components/Navigation/ExternalLink";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout/Callout";
interface CertificateArchiveFormProps {
    certificate: CertificateResource;
    action: ArchiveAction;
    afterAction(): void;
}
interface CertificateArchiveFormState extends DataBaseComponentState {
    format?: CertificateDataFormat;
    password?: SensitiveValue;
    includePrivateKey: boolean;
}
export enum ArchiveAction {
    Archive = "Archive",
    Unachive = "Unarchive"
}
export default class ArchiveCertificate extends DataBaseComponent<CertificateArchiveFormProps, CertificateArchiveFormState> {
    constructor(props: CertificateArchiveFormProps) {
        super(props);
        this.state = {
            includePrivateKey: false,
        };
    }
    async onOk() {
        if (this.props.action === ArchiveAction.Archive) {
            await repository.CertificateConfiguration.archive(this.props.certificate);
        }
        else {
            await repository.CertificateConfiguration.unarchive(this.props.certificate);
        }
        this.props.afterAction();
        return true;
    }
    render() {
        const archive = this.props.action === ArchiveAction.Archive;
        const body = archive ? (<div>
                <p>Are you sure you want to archive this certificate?</p>
                <Callout title="Warning" type={CalloutType.Warning}>
                    <ExternalLink href="ArchiveCertificate">Archived certificates</ExternalLink> are not available for selection as variable values.
                </Callout>
            </div>) : (<div>
                <p>Are you sure you want to unarchive this certificate?</p>
                <Callout title="Note" type={CalloutType.Information}>
                    This certificate will be unarchived.
                </Callout>
            </div>);
        return (<OkDialogLayout title={archive ? "Archive Certificate" : "Unarchive Certificate"} busy={this.state.busy} errors={this.errors} onOkClick={() => this.onOk()}>
                {body}
            </OkDialogLayout>);
    }
    static displayName = "ArchiveCertificate";
}
