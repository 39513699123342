import type { VariableSetType, VariablesScopedToDocumentResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import styles from "~/areas/configuration/components/AuditLayout/AuditStream/style.module.less";
import { ScopeType } from "~/areas/variables/VariableSorting/sortVariables";
import { Callout, CalloutType } from "~/primitiveComponents/dataDisplay/Callout/index";
interface SinglyScopedVariableWarningsProps {
    variablesScopedToThisDocument: VariablesScopedToDocumentResource;
    documentType: ScopeType;
}
export const SinglyScopedVariableWarnings = ({ variablesScopedToThisDocument, documentType }: SinglyScopedVariableWarningsProps) => {
    if (variablesScopedToThisDocument) {
        const projectWarnings = createListOfWarningsFromVariableMap(variablesScopedToThisDocument.VariableMap, "Project");
        if (variablesScopedToThisDocument.HasUnauthorizedProjectVariables) {
            const otherVariablesInWarnings = projectWarnings.length > 0 ? "Other variables" : "Variables";
            projectWarnings.push(<li>{otherVariablesInWarnings} exist in projects for which you do not have edit permissions</li>);
        }
        const libraryWarnings = createListOfWarningsFromVariableMap(variablesScopedToThisDocument.VariableMap, "Library Variable Set");
        if (variablesScopedToThisDocument.HasUnauthorizedLibraryVariableSetVariables) {
            const otherVariablesInWarnings = libraryWarnings.length > 0 ? "Other variables" : "Variables";
            libraryWarnings.push(<li>{otherVariablesInWarnings} exist in library variable sets for which you do not have edit permissions</li>);
        }
        const documentTypeName = ScopeType[documentType].toLowerCase();
        const warningMessage = projectWarnings.length > 0 || libraryWarnings.length > 0 ? (<Callout type={CalloutType.Warning} title="Warning">
                    <p>
                        Deleting this {documentTypeName} will also delete <strong>all</strong> the variables that are scoped only to this {documentTypeName} in the following locations:
                    </p>
                    {projectWarnings.length > 0 && (<div>
                            <h5>Projects</h5>
                            <ul className={styles.list}>{projectWarnings}</ul>
                        </div>)}
                    {libraryWarnings.length > 0 && (<div>
                            <h5>Library Variable Sets</h5>
                            <ul className={styles.list}>{libraryWarnings}</ul>
                        </div>)}
                </Callout>) : null;
        return <div>{warningMessage}</div>;
    }
    else {
        return <></>;
    }
};
const createListOfWarningsFromVariableMap = (variableMap: Record<VariableSetType, Record<string, number>>, variableSetType: VariableSetType) => {
    const warnings: JSX.Element[] = [];
    if (variableMap[variableSetType]) {
        Object.keys(variableMap[variableSetType]).forEach((key) => {
            const value = variableMap[variableSetType][key];
            const variableSuffix = value > 1 ? "s" : "";
            warnings.push(<li key={key}>
                    {value} variable{variableSuffix} in <strong>{key}</strong>
                </li>);
        });
    }
    return warnings;
};
