import type { TaskStatusMessage, TaskStatusMessages } from "@octopusdeploy/octopus-server-client";
import { TaskStatusMessageCategory } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import Markdown from "~/components/Markdown/index";
import { Callout, CalloutType } from "~/primitiveComponents/dataDisplay/Callout/index";
interface TaskQueueMessagesProps {
    messages?: TaskStatusMessages;
}
function getCalloutType(message: TaskStatusMessage): CalloutType {
    switch (message.Category) {
        case TaskStatusMessageCategory.Success:
            return CalloutType.Success;
        case TaskStatusMessageCategory.Information:
            return CalloutType.Information;
        case TaskStatusMessageCategory.Warning:
            return CalloutType.Warning;
        case TaskStatusMessageCategory.Error:
            return CalloutType.Danger;
    }
}
function sortMessages(a: TaskStatusMessage, b: TaskStatusMessage) {
    const sortOrder = [TaskStatusMessageCategory.Error, TaskStatusMessageCategory.Warning, TaskStatusMessageCategory.Information, TaskStatusMessageCategory.Success];
    return sortOrder.indexOf(a.Category) - sortOrder.indexOf(b.Category);
}
export default (props: TaskQueueMessagesProps): React.ReactElement | null => {
    const { messages } = props;
    if (!messages) {
        return null;
    }
    return (<>
            {messages.Messages.sort(sortMessages).map((m) => (<Callout type={getCalloutType(m)} title={m.Title} key={m.Message}>
                    <Markdown markup={m.Message}/>
                </Callout>))}
        </>);
};
