import React from "react";
import type { HotModuleProgress } from "./types";
export const useWebpackHotModuleProgress = () => {
    const [progress, setProgress] = React.useState<HotModuleProgress>({ percent: 0, message: "" });
    const handler = React.useCallback((e: MessageEvent) => {
        if (e.data.type === "webpackProgress") {
            setProgress({ percent: e.data.data.percent, message: e.data.data.msg });
        }
    }, [setProgress]);
    React.useEffect(() => {
        window.addEventListener("message", handler);
        return () => {
            window.removeEventListener("message", handler);
        };
    }, [handler]);
    return progress;
};
