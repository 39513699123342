import { useAnalyticSession } from "@octopusdeploy/portal-analytics";
import type { ActionedFields } from "~/analytics/AnalyticSession";
import type { Action, AnalyticsDispatchEvent, ResourceEvent } from "~/analytics/Analytics";
import { usePageFields } from "~/analytics/usePageFields";
export interface QuestionnaireData {
    technologyStack?: string;
    buildServer?: string;
    packageHost?: string;
    hostingPlatform?: string;
    runbookOnly?: string;
}
export interface QuestionnaireEvent extends ResourceEvent {
    action: Action;
    data?: QuestionnaireData;
}
export type AnalyticQuestionnaireDispatcher = AnalyticsDispatchEvent<QuestionnaireEvent>;
function mapQuestionnaireDataToAnalyticFields(data?: QuestionnaireData) {
    return {
        ...Prop("Technology Stack", data?.technologyStack),
        ...Prop("Build Server", data?.buildServer),
        ...Prop("Package Host", data?.packageHost),
        ...Prop("Hosting Platform", data?.hostingPlatform),
        ...Prop("Runbook Only", data?.runbookOnly),
    };
}
export function useOnboardingQuestionnaireSubmittedDispatch(): AnalyticQuestionnaireDispatcher {
    const session = useAnalyticSession();
    const pageFields = usePageFields();
    return (name: string, event: QuestionnaireEvent) => {
        if (!pageFields) {
            return;
        }
        const fields: ActionedFields = {
            "Action Type": event.action,
            ...pageFields,
            "Resource Type": event.resource,
            ...mapQuestionnaireDataToAnalyticFields(event.data),
        };
        session.track(name, fields);
    };
}
function Prop(name: string, value?: string): object {
    return value !== undefined ? { [name]: value } : {};
}
