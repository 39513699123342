import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import ExternalLink from "../Navigation/ExternalLink/ExternalLink";
import InternalLink from "../Navigation/InternalLink/InternalLink";
import { isInternalLinkPart, isTextPart, parseMarkdownLinks } from "./parseLinks";
interface InternalTextWithLinksProps {
    message: string;
    allowHtml: boolean;
}
type InternalLinkProps = InternalTextWithLinksProps & RouteComponentProps<{
    spaceId: string;
}>;
class TextWithLinks extends React.Component<InternalLinkProps> {
    render() {
        return parseMarkdownLinks(this.props.message).map((part, index) => {
            if (isTextPart(part)) {
                return this.props.allowHtml ? <span key={index} dangerouslySetInnerHTML={{ __html: part.content }}/> : <span key={index}>{part.content}</span>;
            }
            if (isInternalLinkPart(part)) {
                return (<InternalLink key={index} to={part.internalUrl}>
                        {part.text}
                    </InternalLink>);
            }
            return (<ExternalLink key={index} href={part.externalUrl}>
                    {part.text}
                </ExternalLink>);
        });
    }
    static displayName = "TextWithLinks";
}
export default withRouter(TextWithLinks);
