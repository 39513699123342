import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import type { NotificationItem } from "~/components/Navbar/notifications.redux";
import { notificationActions, NotificationType } from "~/components/Navbar/notifications.redux";
import { getCurrentValue } from "./notificationLocalState";
const notificationId = "Notification/GitVariables";
function useGitVariablesNotification(): NotificationItem | undefined {
    const notificationWasDismissed = getCurrentValue(notificationId);
    const gitVariablesNotificationsAreEnabled = isFeatureToggleEnabled("GitVariablesNotificationFeatureToggle");
    if (!gitVariablesNotificationsAreEnabled || notificationWasDismissed) {
        return undefined;
    }
    return {
        id: notificationId,
        text: "Store project variables in Git",
        description: "You can now store your project variables in Git alongside your deployment process and settings.",
        links: [
            { type: "External", label: "Learn more", href: "ConfigAsCodeVariables" },
            { type: "External", label: "Feedback", href: "CaCEAPFeedbackForm" },
        ],
        type: NotificationType.info,
    };
}
export default function GitVariablesNotifications() {
    const dispatch = useDispatch();
    const notification = useGitVariablesNotification();
    useEffect(() => {
        if (notification !== undefined) {
            dispatch(notificationActions.raiseNotification(notification));
        }
        else {
            dispatch(notificationActions.dismissNotification(notificationId));
        }
    }, [notification, dispatch]);
    return <></>;
}
