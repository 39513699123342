import { useAnalyticSession } from "@octopusdeploy/portal-analytics";
import type { ThemePaletteType } from "~/theme/index";
interface ThemeChangedOptions {
    selectedTheme: ThemePaletteType;
    previousTheme: ThemePaletteType;
}
const ThemeChangedEventName = "Theme Changed";
export function useNotifyThemeChanged() {
    const session = useAnalyticSession();
    return (options: ThemeChangedOptions) => {
        session.setTheme(options.selectedTheme);
        session.track(ThemeChangedEventName, mapOptionsToAnalyticFields(options));
    };
}
interface NotifyInitialThemeFields {
    "Selected Theme": ThemePaletteType;
    "Previous Theme": ThemePaletteType;
}
function mapOptionsToAnalyticFields(options: ThemeChangedOptions): NotifyInitialThemeFields {
    return {
        "Selected Theme": options.selectedTheme,
        "Previous Theme": options.previousTheme,
    };
}
